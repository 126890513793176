.key-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.card-table {
  color: #000;
  display: block;
  font-family: 'Roboto', sans-serif;
  color: #212529;
}

.card-table th {
  font-weight: 500;
}

.page-link:hover {
  color: #64b0f2;
}

.page-item.active .page-link, .page-item.active .page-link:hover {
  background-color: #64b0f2;
  border-color: #64b0f2;
  color: #fff;
}

select, input[type="search"] {
  font-size: 14px !important;
}

select {
  padding: 4px !important;
}

div.hide-dates table td:nth-of-type(1), div.hide-dates table th:nth-of-type(1) {
  display: none;
}
