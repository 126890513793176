.login-flag-holder {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 105px;
    height: 20px;
}

.flag {
    display: inline-block;
    width: 30px;
    cursor: pointer;
    margin-right: 5px;
}

.account-bg-statements {
    margin-top: -3em;
    width: 100%;
}