.position-fixed {
  position: fixed !important;
}

.email-holder {
  position: absolute;
  bottom: 38px;
  left: 0px;
  right: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  color: #2B3D51;
  border-bottom: 1px solid #E5E5E5;
}

.settings-cog {
  color: #ccc;
}

.settings-cog:hover {
  color: #2B3D51;
  cursor: pointer;
}

.sidebar-flag-holder {
  position: absolute;
  height: 20px;
  left: 5px;
  bottom: 8px;
}