.result-content {
  /*flex: 1;*/
  height: calc(100% - 80px);
}

.graphWrapper {
  min-width: 0 !important; /* Important for responsiveness */
  min-height: 0 !important; /* Important for responsiveness */

  width: 100%;
  height: 100%;
  float: left;
}

.expanded1 {
  width: calc(33.33% - 25px) !important;
}

.expanded2 {
  width: calc(50% - 25px) !important;
}

.expanded3 {
  width: calc(66.67% - 25px) !important;
}

.tableWrapper {
  height: 100%;
  float: right;
  overflow-y: auto;
  color: #212529;
}

.resultsTable {
  width: 100%;
}


.resultsTable td:nth-child(1) {
  width: 30%;
}

.resultsTable td:nth-child(2) {
  width: 25%;
}

.resultsTable td:nth-child(3) {
  width: 25%;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}