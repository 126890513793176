.drop-to-left {
  right: 0;
  left: auto;
}

.dropdown-active, .dropdown-active:hover {
  color: white;
  background-color: #64B0F2;
}

.dropdown-inactive-grey {
  color: grey;
}