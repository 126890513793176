.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single, .irs--flat .irs-bar {
    background-color: black;
    font-size: 12px !important;
}

.irs-from:before, .irs-to:before, .irs-single:before {
    border-top-color: black;
}

.irs--flat .irs-handle>i:first-child {
    background-color: black;
    width: 15px !important;
    margin-left: -2px !important;
    border-radius: 3px !important;
}

.survey-netpromoter {
    margin-top: 10px;
    margin-left: 3%;
    width: 50%;
    background: none;
    background-color: #fbfbfb;
    border: 2px solid #d5d5d5;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: none;
    -webkit-transition: none;
    color: #999;
    height: 44px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 25px;
    font-family: 'Open Sans', Arial, sans-serif;
    line-height: 1.428571429;
    padding: 6px 12px
}