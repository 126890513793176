.filter-list {
    height: 34px;
    border: 1px solid #ccc;
    background-color: #fff;
    overflow: hidden;

    -moz-box-shadow:    inset 0 2px 2px #eee;
    -webkit-box-shadow: inset 0 2px 2px #eee;
    box-shadow:         inset 0 2px 2px #eee;
}

.filter-placeholder {
    color: #ccc;
    line-height: 30px;
    font-weight: bold;
    font-size: 17px;
    margin-left: 7px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}