.settings-box {
    position: relative;
    background-color: white;
    margin: -10px;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    z-index: 1;

    -webkit-box-shadow: 0px -1px 21px 1px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px -1px 21px 1px rgba(0,0,0,0.35);
    box-shadow: 0px -1px 21px 1px rgba(0,0,0,0.35);
}

.settings-colorbox {
    width: 35px;
    height: 35px;
    border-radius: 2px;
}

.settings-swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}

.settings-popover {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    margin-top: -152px;
    margin-left: -100px;
}

.settings-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

body .default.text {
    color: #000 !important;
}

.translation-flag {
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.translation-flag-container {
    width: 100%;
}

.translation-flag-description {
    display: block;
    margin-top: -4px;
    margin-bottom: 7px;
    color: #818a91;
}