.component-top-margin {
    margin-top: 80px;
}

.component-bottom-margin {
    margin-bottom: 80px;
}

.inactive-component {
    opacity: 0.4;
}

.bottom-of-page {
    position: absolute;
    bottom: 0;
}

/* Arabic classes */
.arabic-text {
    text-align: right;
    direction: rtl;
}
