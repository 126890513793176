.filter-list-item {
    background-color: #64B0F2;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    float: left;

    display: inline-block;
}