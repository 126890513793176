.fix-unordered-list-style {
    list-style-type: decimal;
    list-style-position: inside;
    list-style-image: none;
}

.fix-ordered-list-style {
    list-style-type: disc;
    list-style-position: inside;
    list-style-image: none;
}

.fix-alphabetic-list-style {
    list-style-type: lower-alpha;
    list-style-position: inside;
    list-style-image: none;
    text-indent: 30px;
}

.privacy-content {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;;
}

.privacy-content h4 {
    display: inline;
    width: 100%;
}

li {
    display: list-item;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .addition {
      color: white;
  }