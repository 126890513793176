.page-title {
    color: white;
    font-size: 30px;
    line-height: 68px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.project-dropdown-nav {
    line-height: 68px;
    margin-right: 10px;
}

.navItem {
    margin-right:5px
}

.nav {
    margin:0;
}

.top-title {
    margin-left: 5vw;
}

.hidden {
    display: none
}
