.add-component {
    border: 2px dashed #ccc;
    border-radius: 8px;
    width: 100%;
    height: 120px;

    font-size: 25px;
    font-weight: bold;
    color: #888888;
    text-align: center;
    line-height: 120px;
    cursor: pointer;
}

.add-component:hover {
    color: #027DCB;
}