.loading-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.loader {
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;

    font-size: 10px;
    border: .7em solid rgba(218, 219, 223, 1);
    border-left: .7em solid rgba(55, 174, 232, 1);
    animation: spin 1.1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
