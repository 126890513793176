.table td {
  line-height: 2;
}

.checkbox {
  margin-top: -12px;
  line-height: 1.5;
}

.fade {
  opacity: 1;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover {
  background-color: #fff;
}

.admin-tab-container {
  background-color: #fff;
  margin-top: -10px;
  margin-right: -8px;
  margin-left: -8px;
  margin-bottom: -15px;
  padding-top: 10px;
  min-height: calc(100vh - 66px);
}

.ui.checkbox label {
  padding-left: 5px;
}