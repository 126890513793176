* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    line-height: 1.4;
    background-color: #f6f8f9;
}

a {
    color: #333;
    text-decoration: none;
}

.content {
    margin-top: 60px !important;
}
