.open-option-label {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    width: 100%;
    font-size: 14px;
}

.weight-label {
    font-size: 12px;
}

.weight-control {
    width: 80px;
    height: 30px;
    display: inline-block;
}