.color-box {
    position: relative;

    width: 20px;
    height: 20px;
    margin-left: 5px;
    float: right;

    /*border: 1px solid #fff;
    outline: 1px solid #ddd;*/

    display: inline-block;
}