body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card-box {
  padding: 15px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.f-right {
  float: right;
}

.f-left {
  float: left;
}

.d-block {
  display: block;
}

.clear-right {
  clear: right;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.full-size {
  width: 100%;
  height: 100%;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.align-right {
  align-items: flex-end;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}
