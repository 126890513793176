.CardBase {
  padding: 12px;
  color: #6c757d;

  border-radius: 5px;
  margin: 10px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  /*transition: all 0.3s cubic-bezier(.25,.8,.25,1);*/
  display: inline-block;
}

.CardBase:hover {
  /*transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  box-shadow: 0 5px 5px 0px rgba(0,0,0,0.1), 0 5px 5px 0px rgba(0,0,0,0.1);
  margin-top: 5px;
  margin-bottom: 15px;*/
}

.card-content {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

h4 {
  color: #2b3d51;
}

.dimension-title {
  color: #ababab;
  font-size: 16px;
}

.top {
  overflow: hidden; /* Ensures the div has size */
  padding: 4px;
  margin: -2px;

  flex: 0 0 auto;
}

.card-text {
  margin-bottom: 15px;

  flex: 0 1 auto;
}

.chartTitle {
  font-size: 14px;
  float: left;
  font-weight: bold;
  text-transform: uppercase;
}

.optionButtons {
  float: right;
  display: inline-block;
}

.optionButton {
  background-color: #fff;
  border: 4px solid #fff;
  border-radius: 2px;
}

.optionButton:hover {
  background-color: #eee;
  border: 4px solid #eee;
}

.optionButton:active {
  background-color: #bbb;
  border: 4px solid #bbb;
}

.width1 {
  grid-column: auto / span 1;
}

.height1 {
  grid-row: auto / span 1;
  height: 300px;
}

.width2 {
  grid-column: auto / span 2;
}

.height2 {
  grid-row: auto / span 2;
  height: 550px;
}

.width3 {
  grid-column: auto / span 3;
}

.height3 {
  grid-row: auto / span 3;
  height: 880px;
}

.width4 {
  grid-column: auto / span 4;
}