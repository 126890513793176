.select-component-container {
    position: relative;
    margin-bottom: 40px;
}

.component-container {
    border-radius: 3px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
}

.question-component-label {
    font-weight: bold;
}

.question-component-type {
    width: 100%;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

.unfocused {
    display: none;
}

.focused-border {
    border: 1px dashed #ccc;
    border-radius: 3px;
    padding: 10px;
    position: relative;
    margin-bottom: 20px;
}

.edit-button {
    width: 30px;
    height: 30px;
    padding: 0px;
    border-radius: 2px;
    position: absolute;
    top: 1px;
    right: 1px;
    background-color: white;
    z-index: 1;
}

.edit-button:hover {
    background-color: #ccc;
}

.question-preview-container {
    margin-left: 10px;
}

.weight-input {
    width: 80px;
    height: 30px;
}