.survey-frame {
     position: relative;
     margin-left: -8px;
     margin-right: -8px;
     margin-top: 8px;
     border-bottom: 2px solid #ccc;
     min-height: calc(100% - 322px);
 }

 .survey-color-block {
     height: 500px;
     width: 100%;
     overflow: auto;
 }

 .new-page:before {
     padding: 7px 20px 7px 20px;
     content: "";
 }

.editable-text {
    border: 1px dashed rgba(255, 255, 255, 0.6);
    padding: 5px;
}

.editable-text-dark {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    padding: 5px;
}

.translation-indicator-holder {
    position: fixed;
    bottom: 10px;
    left: 260px;
    width: 60px;
    height: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 2;
}