.benchmark-card {
    padding: 12px;
    padding-top: 15px;
    color: #6c757d;

    border-radius: 5px;
    margin: 10px;
    width: calc(100% - 20px);
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    display: block;
}

.cluster-label {
    font-size: 15px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
}

.benchmark-divider {
    margin-top: 15px;
    margin-bottom: 25px;
}

.benchmark-title {
    margin-left: 5px;
    margin-bottom: 5px;
}

.benchmark-radiobutton {
    display: inline-block;
    margin-top: 20px;
    margin-left: 10px;
}

.no-survey-cluster-placeholder {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #aaa;
    margin-top: 100px;
    margin-bottom: 100px;
}

.select-deselect-btn {
    width: 100%;
    font-size: 18px;
}

.form-check label {
    margin-left: 5px;
    font-weight: normal;
}

.dropdown-menu .actions-btn {
    font-size: 14px !important;
    width: 100%;
    margin-bottom: 5px;
}

.dropdown-menu {
    max-height: 300px;
    overflow-x: hidden;
}