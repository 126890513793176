.email-textbox {
    width: 200px;
    display: inline-block;
    margin-right: 5px;
}

.email-label {
    display: inline-block;
}

.custom-file-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.remove-file-button {
    margin-top: 6px;
    font-size: 22px;
    color: #777;
    cursor: pointer;
}

.recipients-file-buttons {
    height: 34px;
    line-height: 34px;
    vertical-align: text-bottom;
}