.select-survey {
    position: absolute;
    width: 800px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin-left: -250px; /* margin is -0.5 * dimension */
    margin-top: -100px;
}

.survey-icon {
    font-size: 75px;
}

.t-a-left {
    text-align: left;
}