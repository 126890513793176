.GraphCardHolder {
    /*display: flex;
    align-content: stretch;
    flex-wrap: wrap;*/

    display: grid;
    grid-auto-flow: dense;
    #margin-top: 45px;
}

.holder-spinner {
    top: 161px !important;
    left: 300px !important;
}

.options-bar {
    position: absolute;
    top: 70px;
    left: 250px;
    right: 0px;
    height: 45px;
    background: white;
    z-index: 100;

    border-bottom: 1px solid #ccc;

    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: row;
}

.options-bar-divider {
    margin-top: 6px;
    height: 32px;
    width: 1px;
    display: inline-block;
    background-color: #ccc;
}

.range-slider-holder {
    margin-left: 35px;
    margin-right: 35px;
}

.no-results-placeholder {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #aaa;
    margin-top: 80px;
}

@media only screen and (min-width: 0px) {
    .ui.selection.dropdown.dropdown-full-height .menu {
        max-height: none !important;
    }
}

@media only screen and (min-width: 600px) {
    .GraphCardHolder {
        grid-template-columns: repeat(1, 1fr);
    }

    GraphCard {
        width: 100% !important;
    }
}

@media only screen and (min-width: 1000px) {
    .GraphCardHolder {
        grid-template-columns: repeat(2, 1fr);
    }

    GraphCard {
        width: 50% !important;
    }
}

@media only screen and (min-width: 1200px) {
    .GraphCardHolder {
        grid-template-columns: repeat(3, 1fr);
    }

    GraphCard {
        width: 33% !important;
    }
}

@media only screen and (min-width: 1400px) {
    .GraphCardHolder {
        grid-template-columns: repeat(4, 1fr);
    }

    GraphCard {
        width: 25% !important;
    }
}


