.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single, .irs--flat .irs-bar {
    background-color: #64b0f2 !important;
}

.irs-from:before, .irs-to:before, .irs-single:before {
    border-top-color: #64b0f2 !important;
}

.irs--flat .irs-handle>i:first-child {
    background-color: #4a85b9 !important;
    width: 5px !important;
    margin-left: -2px !important;
    border-radius: 3px !important;
}